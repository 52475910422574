<script>
export default {
  data() {
    return {
      windowHeight: window.innerHeight
    };
  },
  computed: {
    dsTableDimension() {
      const windowHeight = this.windowHeight;
      let newVal = {
        tableHeight: 250,
        colWidth: {}
      };

      if (windowHeight >= 720 && windowHeight < 1024) {
        newVal.tableHeight = 290;
      } else if (windowHeight >= 1024 && windowHeight <= 1280) {
        newVal.tableHeight = 450;
      } else if (windowHeight > 1280) {
        newVal.tableHeight = 600;
      }

      if (this.operationName === "update") {
        newVal.colWidth.allowUpdate = "15";
        newVal.colWidth.columnName = "17.5";
        newVal.colWidth.type = "17.5";
        newVal.colWidth.value = "50";
      } else {
        newVal.colWidth.allowUpdate = "0";
        newVal.colWidth.columnName = "20";
        newVal.colWidth.type = "20";
        newVal.colWidth.value = "60";
      }

      return newVal;
    },
    formTableDimension() {
      const windowHeight = this.windowHeight;
      let newVal = {
        tableHeight: 300,
        colWidth: {}
      };

      if (windowHeight >= 720 && windowHeight <= 1024) {
        newVal.tableHeight = 320;
      } else if (windowHeight > 1024 && windowHeight <= 1280) {
        newVal.tableHeight = 470;
      } else if (windowHeight > 1280) {
        newVal.tableHeight = 600;
      }

      return newVal;
    }
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowHeight);
  },
  methods: {
    updateWindowHeight() {
      this.windowHeight = window.innerHeight;
    }
  }
};
</script>
