<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      v-loading="loading"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input
          v-model="nodeToBind.node_name"
          :placeholder="__('Please enter Datastore node name')"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="data_store_node.data.data_store_id"
        :label="__('Datastore')"
      >
        <el-select
          v-model="nodeToBind.data_store_node.data.data_store_id"
          style="width: 100%;"
          :placeholder="__('Please select a Datastore')"
          default-first-option
          filterable
          :loading-text="__('fetching Datastores list')"
          @change="updateColumnList($event)"
        >
          <el-option
            v-for="(item, index) in sortedDataStores"
            :label="dsLabel(item.display_name)"
            :value="item.data_store_id"
            :key="index"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        prop="data_store_node.data.operation_name"
        :label="__('Action')"
      >
        <el-select
          v-model="operationName"
          style="width: 100%;"
          :placeholder="__('Please select an action')"
          default-first-option
          @change="operationNameChange"
        >
          <el-option
            v-for="(item, index) in dsAction"
            :label="item.label"
            :value="item.value"
            :key="index"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="__('Debug Logs')"
        v-if="isDebugLogShow"
        class="is-debug-log"
      >
        <el-checkbox v-model="isDebugLog">
          Store debug logs. To populate the datastore, use Edit Task to enable
          debug logs.
        </el-checkbox>
      </el-form-item>
      <el-form-item :label="__('Row Limit')" v-if="rowLimitOrderByShow">
        <el-select
          v-model="rowLimit"
          style="width: 100%;"
          default-first-option
          filterable
          :placeholder="__('Set a row limit')"
        >
          <el-option
            v-for="(item, index) in generateRowLimit"
            :label="item"
            :value="item"
            :key="index"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        prop="data_store_node.data.query_condition"
        :label="__('Query Condition')"
        v-if="queryConditionShow"
      >
        <template>
          <!-- propagate input, for your component to work with v-model -->
          <query-condition-builder
            :fields="columnsForQueryBuilder"
            :value="eb"
            :fieldTypes="fieldTypes"
            :operators="operators"
            v-loading="loading"
            @previewDataStore="previewResponse"
          >
          </query-condition-builder>
        </template>
      </el-form-item>
      <el-row type="flex">
        <el-col :span="23">
          <el-form-item :label="__('Order by')" v-if="rowLimitOrderByShow">
            <el-select
              v-model="orderBy.column"
              style="width: 100%;"
              default-first-option
              filterable
              clearable
              :placeholder="__('Select a orderby column (optional)')"
              @clear="handleOrderByClear"
              @change="handleOrderBySelection"
              ref="orderBy"
            >
              <el-option
                v-for="(item, index) in selectedDsCollectionStructure"
                :label="item.label"
                :value="item.name"
                :key="index"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-form-item label="Sort" v-if="rowLimitOrderByShow">
            <el-select
              v-model="orderBy.sortBy"
              default-first-option
              filterable
              :placeholder="__('Select a sort type')"
            >
              <el-option
                v-for="(item, index) in sortType"
                :label="item.label"
                :value="item.value"
                :key="index"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="23">
          <el-form-item v-if="rowLimitOrderByShow && showC1">
            <el-select
              v-model="additionalOrderBy[0].column"
              style="width: 100%;"
              default-first-option
              filterable
              clearable
              :placeholder="
                __('Select an additional orderby column (optional)')
              "
              ref="additionalOrderByC1"
              @clear="handleAdditionalOrderByClear"
              @change="handleAdditionalOrderBySelection"
            >
              <el-option
                v-for="(item, index) in selectedDsCollectionStructure"
                :label="item.label"
                :value="item.name"
                :key="index"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-form-item v-if="rowLimitOrderByShow && showC1">
            <el-select
              v-model="additionalOrderBy[0].sortBy"
              default-first-option
              filterable
              :placeholder="__('Select a sort type')"
            >
              <el-option
                v-for="(item, index) in sortType"
                :label="item.label"
                :value="item.value"
                :key="index"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="23">
          <el-form-item v-if="rowLimitOrderByShow && showC2">
            <el-select
              v-model="additionalOrderBy[1].column"
              style="width: 100%;"
              default-first-option
              filterable
              clearable
              :placeholder="
                __('Select an additional orderby column (optional)')
              "
              @change="handleAdditionalOrderBySelection"
              ref="additionalOrderByC2"
            >
              <el-option
                v-for="(item, index) in selectedDsCollectionStructure"
                :label="item.label"
                :value="item.name"
                :key="index"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-form-item v-if="rowLimitOrderByShow && showC2">
            <el-select
              v-model="additionalOrderBy[1].sortBy"
              default-first-option
              filterable
              :placeholder="__('Select a sort type')"
            >
              <el-option
                v-for="(item, index) in sortType"
                :label="item.label"
                :value="item.value"
                :key="index"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- Label has to be dynamic based on the action selected -->

      <expression-builder-dialog
        :value="expressions(variableToSet)"
        :show-expression-builder="displayExpressionBuilderModal"
        @input="saveExpression($event)(variableToSet)"
        :source="source"
        @cancel="handleCancel"
        @close="handleCancel"
        use-secure-variables
      />
      <el-form-item
        v-if="jsonPathSelectorShow"
        :label="__('Collection Structure')"
        class="response"
      >
        <el-divider></el-divider>
        <test-web-service
          :response="collectionStructureJson"
          :response-editable="false"
          json-path-editable
          :show-edit-btn="false"
          :show-preview-button="false"
          :enable-preview-button="false"
          :audio-variable-cols="audioColumns"
          :secure-variable-cols="secureColumns"
          @rule-added="addVariableRule"
        />
        <el-row v-if="!emptyVariableRules" type="flex" style="margin-top: 20px">
          <el-col>
            <el-table
              class="json-paths"
              fit
              :data="getVariableRules"
              style="width: 100%"
            >
              <el-table-column :label="__('JSON Path')" prop="rule_value">
                <template slot-scope="scope">
                  {{ prettify(jsonPathValue(scope.row.rule_value, variables)) }}
                </template>
              </el-table-column>
              <el-table-column :label="__('Variable')" prop="variable_name">
                <template slot-scope="scope">
                  <variable-dropdown
                    :placeholder="__('Variable Name')"
                    :new-item-message="newVariableMessage(scope.row)"
                    :studioVariables="variablesList(scope.row)"
                    :currentSelect="currentSelection(scope.row)"
                    :variableRule="getVariableRule(scope.row)"
                    :nodeData="nodeToBind.data_store_node"
                    :showNewVariableMessage="false"
                    @updateVariables="handleVariableUpdate"
                    :key="getVariableRule(scope.row) + scope.row.variable_id"
                  />
                </template>
              </el-table-column>

              <el-table-column
                :label="__('Default Value')"
                prop="default_value"
              >
                <template slot-scope="scope">
                  <input-variable-popper
                    v-model="scope.row.default_value"
                    v-if="
                      scope.row.variable_type !== 'audio' &&
                        scope.row.variable_type !== 'secure'
                    "
                    :is-text-area="false"
                    force-reinitialize
                    :placeholder="__('Default Value')"
                    :include-secure-variables="false"
                  ></input-variable-popper>
                </template>
              </el-table-column>
              <el-table-column
                class-name="row-message"
                width="125px"
                prop="msg"
              >
                <template slot-scope="scope">
                  <span
                    v-if="newVariableDetected(scope.row)"
                    class="row-message"
                    >{{ __("new variable") }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column class-name="cell-item-pointer" width="50px">
                <template slot-scope="scope">
                  <span @click="removeJsonPath(scope.row)">
                    <i class="el-icon-circle-close"></i>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- Label has to be dynamic based on the action selected -->
      <el-form-item
        prop="data_store_node.data.document_rules"
        :label="__('Assign values to Datastore columns')"
        v-if="assignColumnValuesTableShow"
      >
        <el-table
          :data="getDocumentRules"
          :max-height="dsTableDimension.tableHeight"
          style="width: 100%"
        >
          <el-table-column
            prop="is_active"
            :label="__('Allow Update')"
            :min-width="dsTableDimension.colWidth.allowUpdate"
            v-if="updateActionSelected"
          >
            <template slot-scope="scope">
              <el-col>
                <el-row type="flex">
                  <el-form-item>
                    <el-checkbox
                      :value="documentRuleActive(scope.row)"
                      @input="setDocumentRuleActiveState($event)(scope.row)"
                    ></el-checkbox>
                  </el-form-item>
                </el-row>
              </el-col>
            </template>
          </el-table-column>
          <el-table-column
            prop="rule_column_name"
            :label="__('Column Name')"
            :min-width="dsTableDimension.colWidth.columnName"
          >
          </el-table-column>
          <el-table-column
            :label="__('Type')"
            prop="rule_column_type"
            :min-width="dsTableDimension.colWidth.type"
          >
          </el-table-column>
          <el-table-column
            prop="rule_column_value"
            :label="__('Value')"
            :min-width="dsTableDimension.colWidth.value"
          >
            <template slot-scope="scope">
              <el-row
                v-if="scope.row.rule_column_type"
                type="flex"
                class="ruleValue extract-words-column"
              >
                <el-col>
                  <el-form-item v-if="!useExpressionBuilder">
                    <el-input v-model="scope.row.rule_column_value"></el-input>
                  </el-form-item>
                  <el-form-item
                    v-else-if="scope.row.rule_column_type === 'Audio'"
                  >
                    <el-select
                      v-model="scope.row.rule_column_value"
                      default-first-option
                      filterable
                      :placeholder="__('Select Audio Variable')"
                      :disabled="updateActionSelected && !scope.row.is_active"
                      class="extract-words-column"
                      clearable
                    >
                      <el-option
                        v-for="audioVariable in audioVariables"
                        :key="audioVariable.variable_id"
                        :label="audioVariable.variable_name"
                        :value="'{{' + audioVariable.variable_id + '}}'"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <!--                  <el-form-item-->
                  <!--                    v-else-if="updateActionSelected && !scope.row.is_active"-->
                  <!--                  >-->
                  <!--                    <el-input-->
                  <!--                      disabled-->
                  <!--                      :value="scope.row.rule_column_value"-->
                  <!--                    ></el-input>-->
                  <!--                  </el-form-item>-->
                  <el-form-item v-else>
                    <expression-input
                      @edit-input="handleVariableSet(scope.row)"
                      v-model="scope.row.rule_column_value"
                      :complex-variables="complexVariables"
                      :inactive="updateActionSelected && !scope.row.is_active"
                    ></expression-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item
        :label="__('Assign created Row ID to variable')"
        v-if="assignRowIdToVariableShow"
      >
        <variable-dropdown
          :placeholder="__('Select or create a new variable')"
          :new-item-message="__('new variable')"
          :currentSelect="currentlySelectedVariable"
          :variableRule="__('inserted_row_id_var')"
          @updateVariables="handleVariableUpdate"
          :nodeData="nodeToBind.data_store_node"
        />
      </el-form-item>
      <el-form-item
        prop="row_id_delete_update"
        :label="updateDeleteRowIDLabel"
        v-if="assignIdFilterShow"
        class="is-required"
      >
        <input-variable-popper
          v-model="updateOrDeleteIdFilterValue"
          :is-text-area="false"
        />
      </el-form-item>
    </el-form>
    <el-dialog
      v-if="showModal"
      :visible.sync="showModal"
      style="display: flex;align-items: center"
      width="80%"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
      destroy-on-close
      lock-scroll
      :title="dsLabel(dataStoreName)"
    >
      <preview-table
        hide-seq
        :tableData="dsData"
        :tableColumns="selectedDsCollectionStructure"
        :use-audio-file-uploader-in-list="false"
        :defaultColumns="defaultColumnNames"
        show-pagination
        :show-query-condition-builder-icon="false"
        :loading-table="loadingCollectionData"
        @update-sort-event="updateSortEvent"
        @refresh-data-event="refreshDsData"
      >
        <el-form class="form-container" slot="paginationToolbar">
          <pagination-toolbar
            :content-api="getDataStoresCollection"
            :show-search="false"
            :show-slot="true"
            list-changed-event-name="documents-changed"
            @success="handleFetchSuccess"
          >
          </pagination-toolbar>
        </el-form>
      </preview-table>
    </el-dialog>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
import Vue from "vue";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import { mapActions, mapGetters, mapState } from "vuex";

import QueryConditionBuilder from "@/components/queryConditionBuilder/QueryConditionBuilder";
import { getComplexVariables } from "@/api/variables";
import ExpressionBuilderDialog from "@/views/build/callflow/components/expression-builder/ExpressionBuilderDialog";
import VariableDropdown from "@/views/build/callflow/components/node-type-forms/components/VariableDropdown.vue";
import ExpressionInput from "@/views/build/callflow/components/expression-builder/ExpressionInput";
import { filterRowsIfSomeKeyValueIsAbsent } from "@/utils/collection";
import SchemaGenerator from "@/utils/SchemaGenerator";
import PreviewTable from "@/components/queryConditionBuilder/PreviewTable";
import TestWebService from "@/views/build/callflow/components/node-type-forms/components/TestWebService";
import { jsonPathValue } from "@/utils/canvas";
import { prettifyJsonPath } from "@/utils/transformers";
import DataStore from "@/mixins/DataStore";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import PaginationToolbar from "@/components/PaginationToolbar";
import { EventBus } from "@/EventBus";
import { SYSTEM_LOG_DS } from "@/constants/datastore";
import TableDimension from "@/mixins/TableDimension";
import NodeUsesVariableDropdown from "@/views/build/callflow/components/node-type-forms/NodeUsesVariableDropdown.vue";

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

const variableRuleInitialize = {
  rule_value: "",
  variable_name: "",
  variable_id: -1,
  default_value: "",
  array_variable: false
};
export default {
  mixins: [BaseNode, DataStore, TableDimension, NodeUsesVariableDropdown],

  props: {
    useExpressionBuilder: {
      required: false,
      type: Boolean,
      default: true
    },
    source: {
      required: false,
      type: String,
      default: ""
    }
  },
  components: {
    PaginationToolbar,
    InputVariablePopper,
    TestWebService,
    QueryConditionBuilder,
    ExpressionInput,
    ExpressionBuilderDialog,
    PreviewTable,
    VariableDropdown
  },
  data() {
    const validateRowIdForInsertOrUpdate = (rule, value, cb) => {
      if (this.showIDFilterFor.includes(this.operationName)) {
        if (_.isEmpty(this.updateOrDeleteIdFilterValue)) {
          cb(__("Row ID is required"));
        } else {
          cb();
        }
      } else {
        cb();
      }
    };
    return {
      jsonPathValue,
      prettify: prettifyJsonPath,
      rules: {
        "data_store_node.data.data_store_id": [
          {
            required: true,
            message: __("Please select a Datastore"),
            trigger: "blur"
          }
        ],
        "data_store_node.data.operation_name": [
          {
            required: true,
            message: __("Please select an Action type"),
            trigger: "blur"
          }
        ],
        row_id_delete_update: [
          {
            validator: validateRowIdForInsertOrUpdate,
            trigger: "blur"
          }
        ]
      },
      variableRuleInitialize: _.cloneDeep(variableRuleInitialize),
      onlyMeta: ["update", "delete"],
      affectedRowIDFoundInMetaForOps: ["insert"],
      allowQueryConditionFor: ["fetch"],
      allowAssignColumnValuesTableFor: ["update", "insert"],
      allowAssignRowIDToVariableFor: ["insert"],
      showJsonPathSelectorFor: ["fetch"],
      showLimitOrderByFor: ["fetch"],
      showIDFilterFor: ["update", "delete"],
      showIsDebugLogFor: ["update", "insert"],
      variableToSet: {},
      complexVariables: [],
      documentRulesBkup: [],
      showModal: false,
      dataStoreName: "",
      responseMetaData: {
        ws_response_meta: {
          response_code: 0,
          response: ""
        }
      },
      dataTypeSampleValues: {
        integer: 0,
        string: "",
        date: "",
        boolean: false,
        array: [],
        double: 1.0,
        timestamp: "",
        audio: "<audio-content>",
        secure: "**********"
      },
      collectionStructureJson: {},
      sortType: [
        { label: __("Ascending"), value: "asc" },
        { label: __("Descending"), value: "desc" }
      ],
      nodeName: "data_store_node",
      additionalOrderBy: [],
      showC1: false,
      showC2: false
    };
  },
  computed: {
    ...mapState("dataStores", {
      loading: state => state.loading
    }),
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit,
      // clickedNode: state => state.clickedNode,
      showExpressionBuilder: state => state.showExpressionBuilder
    }),
    ...mapGetters("variables", {
      secureVariables: "secureVariables",
      cavVariables: "cavVariables"
    }),
    ...mapGetters("dataStores", {
      dataStores: "datastoresExceptCallMonitorDS"
    }),
    sortedDataStores() {
      return _.orderBy(
        this.dataStores,
        ["is_log", "display_name"],
        ["desc", "asc"]
      );
    },
    documentRuleActive() {
      return row => {
        return !!row.is_active;
      };
    },
    queryConditions: {
      get() {
        return _.get(
          this.nodeToBind,
          "data_store_node.data.query_condition.data.query_condition",
          { children: [], connectionType: "and", maxDepth: 0 }
        );
      },
      set(val) {
        _.set(
          this.nodeToBind,
          "data_store_node.data.query_condition.data.query_condition",
          val
        );
      }
    },
    idCondition() {
      return _.find(
        _.get(this.queryConditions, "children", []),
        item => item.fieldName === "_id"
      );
    },
    idSearchValue: {
      get() {
        return _.get(this.idCondition, "value") || "";
      },
      set(val) {
        if (!_.isEmpty(this.idCondition)) {
          _.set(this.idCondition, "value", val);
        }
      }
    },
    documentId: {
      get() {
        return _.get(this.nodeToBind, "data_store_node.data.document_id") || "";
      },
      set(val) {
        _.set(this.nodeToBind, "data_store_node.data.document_id", val);
      }
    },
    operationName: {
      get() {
        return _.get(this.nodeToBind, "data_store_node.data.operation_name");
      },
      set(val) {
        _.set(this.nodeToBind, "data_store_node.data.operation_name", val);
      }
    },
    queryConditionShow() {
      if (
        this.nodeToBind.data_store_node.data.data_store_id > 0 &&
        !_.isEmpty(this.operationName)
      ) {
        return this.allowQueryConditionFor.includes(this.operationName);
      }
      return false;
    },
    audioColumns() {
      let cs = this.getDataStoreCollectionStructure(
        _.get(this.dataStore, "data_store_id")
      );
      return _(cs)
        .filter(item => item.type === "audio")
        .map(item => item.col_name)
        .value();
    },

    secureColumns() {
      let cs = this.getDataStoreCollectionStructure(
        _.get(this.dataStore, "data_store_id")
      );
      return _(cs)
        .filter(item => item.secure)
        .map(item => item.col_name)
        .value();
    },

    variablesList() {
      return rule => {
        return rule.variable_type === "audio"
          ? this.audioVariables
          : rule.variable_type === "secure"
          ? this.secureVariables
          : this.singleValuedAndSecureVariables;
      };
    },
    emptyVariableRules() {
      return _.isEmpty(this.getVariableRules);
    },
    getVariableRules() {
      return _.filter(
        this.variableRules,
        rule =>
          rule.variable_type !== "array" &&
          rule.rule_value !== "inserted_row_id_var"
      );
    },
    newVariableMessage() {
      return row => {
        return row.variable_type === "audio"
          ? "new audio variable"
          : row.variable_type === "secure"
          ? "new secure variable"
          : "new variable";
      };
    },
    assignColumnValuesTableShow() {
      if (
        this.nodeToBind.data_store_node.data.data_store_id > 0 &&
        !_.isEmpty(this.operationName)
      ) {
        return this.allowAssignColumnValuesTableFor.includes(
          this.operationName
        );
      }
      return false;
    },
    assignIdFilterShow() {
      if (
        this.nodeToBind.data_store_node.data.data_store_id > 0 &&
        !_.isEmpty(this.operationName)
      ) {
        return this.showIDFilterFor.includes(this.operationName);
      }
      return false;
    },
    updateDeleteRowIDLabel() {
      if (this.showIDFilterFor.includes(this.operationName)) {
        return __("Row Id to :operationName", {
          operationName: _.capitalize(this.operationName)
        });
      }
      return __("Row ID");
    },
    assignRowIdToVariableShow() {
      if (
        this.nodeToBind.data_store_node.data.data_store_id > 0 &&
        !_.isEmpty(this.operationName)
      ) {
        return this.allowAssignRowIDToVariableFor.includes(this.operationName);
      }
      return false;
    },
    jsonPathSelectorShow() {
      if (
        this.nodeToBind.data_store_node.data.data_store_id > 0 &&
        !_.isEmpty(this.operationName)
      ) {
        return this.showJsonPathSelectorFor.includes(this.operationName);
      }
      return false;
    },
    insertedRowIdVariableRule() {
      const studioVariableRules = _.find(
        this.nodeToBind.data_store_node.data.variable_rules.data,
        rule => rule.rule_value === "inserted_row_id_var"
      );
      if (studioVariableRules) {
        return studioVariableRules;
      }
      const interactionVariableRules = _.find(
        this.nodeToBind.data_store_node.data.interaction_variable_rules.data,
        rule => rule.rule_value === "inserted_row_id_var"
      );
      if (interactionVariableRules && this.cavVariables.length > 0) {
        const cavVariable = this.cavVariables.find(
          variable =>
            variable.variable_id ===
            parseInt(
              interactionVariableRules.interaction_variable_id.slice(0, -4)
            )
        );
        return {
          variable_name: cavVariable.variable_name,
          variable_id: cavVariable.variable_id.toString() + "|cav",
          ...interactionVariableRules
        };
      }
      return this.variableRuleInitialize;
    },
    rowLimitOrderByShow() {
      if (
        this.nodeToBind.data_store_node.data.data_store_id > 0 &&
        !_.isEmpty(this.operationName)
      ) {
        return this.showLimitOrderByFor.includes(this.operationName);
      }
      return false;
    },
    currentlySelectedVariable() {
      let variableRule = this.insertedRowIdVariableRule;
      const { variable_id, variable_name } = _.isEmpty(variableRule)
        ? this.variableRuleInitialize
        : variableRule;
      return variable_id === -1 ? variable_name : variable_id;
    },

    displayExpressionBuilderModal: {
      get: function() {
        return this.showExpressionBuilder;
      },

      set: function(val) {
        this.setExpressionBuilderModalShow(val);
      }
    },
    //return the saved value to expression builder component
    expressions: {
      get: function() {
        return row => {
          return row.rule_column_value || "{}";
        };
      }
    },
    getDocumentRules() {
      return this.isNodeSubmit
        ? this.documentRulesBkup
        : this.nodeToBind.data_store_node.data.document_rules.data;
    },
    variableRules() {
      return [
        ..._.get(
          this.nodeToBind,
          "data_store_node.data.variable_rules.data",
          []
        ),
        ..._.get(
          this.nodeToBind,
          "data_store_node.data.interaction_variable_rules.data",
          []
        )
      ];
    },
    generateRowLimit() {
      return Array.from(Array(50), (_, i) => i + 1);
    },
    updateActionSelected() {
      return this.operationName === "update";
    },
    dsLabel() {
      return displayName => {
        if (displayName !== undefined && displayName.includes(SYSTEM_LOG_DS)) {
          return __("=== SYSTEM_LOG ===");
        } else {
          return displayName;
        }
      };
    },
    dataStore() {
      return _.find(this.dataStores, {
        data_store_id: _.get(
          this.nodeToBind,
          "data_store_node.data.data_store_id"
        )
      });
    },
    dsAction() {
      let commonAction = [
        { label: __("Fetch"), value: "fetch" },
        { label: __("Insert"), value: "insert" },
        { label: __("Update"), value: "update" }
      ];
      if (this.dataStore && !_.get(this.dataStore, "is_log", 0)) {
        return [...commonAction, { label: __("Delete"), value: "delete" }];
      } else {
        return commonAction;
      }
    },
    isDebugLogShow() {
      if (
        this.nodeToBind.data_store_node.data.data_store_id > 0 &&
        !_.isEmpty(this.operationName)
      ) {
        return this.showIsDebugLogFor.includes(this.operationName);
      }
      return false;
    },
    isDebugLog: {
      get() {
        return _.get(this.nodeToBind, "data_store_node.data.is_debug_log");
      },
      set(val) {
        _.set(this.nodeToBind, "data_store_node.data.is_debug_log", val);
      }
    }
  },
  created() {
    this.rowLimit = this.getDefaultRowLimit(true);
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.data_store_node)
    ) {
      this.$set(this.nodeToBind, "data_store_node", {});
      this.$set(this.nodeToBind.data_store_node, "data", {});
      this.$set(this.nodeToBind.data_store_node.data, "data_store_id", null);
      this.$set(this.nodeToBind.data_store_node.data, "operation_name", "");
      this.$set(this.nodeToBind.data_store_node.data, "is_debug_log", false);
      this.$set(this.nodeToBind.data_store_node.data, "document_id", "");
      this.$set(this.nodeToBind.data_store_node.data, "document_rules", {});
      this.$set(
        this.nodeToBind.data_store_node.data.document_rules,
        "data",
        []
      );
      this.$set(this.nodeToBind.data_store_node.data, "query_condition", {});
      this.$set(
        this.nodeToBind.data_store_node.data.query_condition,
        "data",
        {}
      );
      //initializing variable rules
      this.$set(this.nodeToBind.data_store_node.data, "variable_rules", {});
      this.$set(
        this.nodeToBind.data_store_node.data,
        "interaction_variable_rules",
        {}
      );
      this.$set(
        this.nodeToBind.data_store_node.data.variable_rules,
        "data",
        []
      );
      this.$set(
        this.nodeToBind.data_store_node.data.interaction_variable_rules,
        "data",
        []
      );
      this.$set(this.nodeToBind, "node_type", NODE_TYPES.DATASTORE.NODE_TYPE);
    }

    this.additionalOrderBy.push(
      _.get(
        this.nodeToBind.data_store_node,
        "data.query_condition.data.order_by_column_name.additionalOrderBy.0",
        {
          column: "",
          sortBy: ""
        }
      )
    );

    this.additionalOrderBy.push(
      _.get(
        this.nodeToBind.data_store_node,
        "data.query_condition.data.order_by_column_name.additionalOrderBy.1",
        {
          column: "",
          sortBy: ""
        }
      )
    );

    this.showC1 =
      _.has(
        this.nodeToBind.data_store_node,
        "data.query_condition.data.order_by_column_name.orderBy.column"
      ) &&
      !_.isEmpty(
        _.get(
          this.nodeToBind.data_store_node,
          "data.query_condition.data.order_by_column_name.orderBy.column"
        )
      );

    this.showC2 = _.has(
      this.nodeToBind.data_store_node,
      "data.query_condition.data.order_by_column_name.additionalOrderBy.0"
    );

    this.initializeDataStores();
    this.loadComplexVariables();
  },
  methods: {
    ...mapActions("dataStores", {
      getDataStores: "getDataStores"
    }),

    ...mapActions("canvas", {
      setExpressionBuilderModalShow: "setExpressionBuilderModalShow",
      toggleNodeSubmit: "toggleNodeSubmit"
    }),

    async initializeDataStores() {
      await this.getDataStores();
      this.updateColumnList();
      this.updateQueryConditions();
    },

    refreshDsData(removeCondition = false) {
      let additionalInfo = {};
      if (removeCondition) {
        this.resetConditionValue();
        additionalInfo.page = 1;
      }
      this.$nextTick(() => {
        EventBus.$emit("documents-changed", this.dsData, additionalInfo);
      });
    },
    updateSortEvent(orderBy) {
      this.orderBy = orderBy;
      this.previewResponse();
    },

    setDocumentRuleActiveState(isActive) {
      return row => {
        row.is_active = isActive ? 1 : 0;
      };
    },

    updateQueryConditions() {
      if (
        !_.isEmpty(
          _.get(
            this.nodeToBind,
            "data_store_node.data.query_condition.data.query_condition"
          )
        )
      ) {
        const rowLimitObj = _.get(
          this.nodeToBind,
          "data_store_node.data.query_condition.data.order_by_column_name",
          {}
        );

        const queryConditionParsed = this.nodeToBind.data_store_node.data
          .query_condition.data.query_condition;
        this.rowLimit = rowLimitObj.rowLimit;
        this.orderBy.column = _.get(rowLimitObj, "orderBy.column", "");
        this.orderBy.sortBy = _.get(rowLimitObj, "orderBy.sortBy", "");
        this.setNewQueryCondition(queryConditionParsed);
      }
    },
    async loadComplexVariables() {
      if (_.isEmpty(this.complexVariables) && !_.isEmpty(this.clickedNode)) {
        await getComplexVariables(this.clickedNode.task_id)
          .then(({ data }) => {
            this.complexVariables = data.data;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    //  Update the column values and fields list based on selected datastore
    updateColumnList(dataStoreId) {
      let vm = this;
      let assignedDocumentRuleData;
      let defaultSystemDatastore = _.find(this.dataStores, function(
        dataStoreData
      ) {
        return dataStoreData.display_name.includes("SYSTEM_LOG_DS");
      });

      dataStoreId = dataStoreId
        ? dataStoreId
        : _.get(this.dataStore, "data_store_id");

      if (dataStoreId === undefined) {
        this.nodeToBind.data_store_node.data.data_store_id =
          defaultSystemDatastore.data_store_id;
        dataStoreId = defaultSystemDatastore.data_store_id;
        this.nodeToBind.data_store_node.data.operation_name = "insert";
      }

      let collectionStructure = this.getDataStoreCollectionStructure(
        dataStoreId
      );

      // find dataStore Name
      this.dataStoreName = _.get(this.dataStore, "display_name");

      if (
        this.nodeToBind.data_store_node.data.data_store_id > 0 &&
        !_.isEmpty(this.nodeToBind.data_store_node.data.document_rules.data)
      ) {
        // clone the document_rules data
        assignedDocumentRuleData = _.cloneDeep(
          this.nodeToBind.data_store_node.data.document_rules.data
        );
      }
      // reset the table and query condition fields
      this.nodeToBind.data_store_node.data.document_rules.data = [];
      // reset existing query conditions
      this.resetConditionValue();

      this.reInitializeDataStoreColumns(true);

      _.forEach(collectionStructure, value => {
        // push the query condition fields value
        let fieldType = vm.fieldType(value.type);
        if (
          value.col_name !== "_task_id" &&
          !(value.col_name === "details" && value.type === "object")
        ) {
          if (!_.includes(value.col_name, "details.")) {
            vm.pushNewColumnToCollectionStructure({
              name: value.col_name,
              label: value.label ? value.label : value.col_name,
              secure: !!value.secure,
              type: fieldType,
              choices: null,
              visible: true
            });

            // match the assigned variable value with column
            let assignedVariableValue = _.find(
              assignedDocumentRuleData,
              function(docRule) {
                return docRule.rule_column_name === value.col_name;
              }
            );
            // push the document_rules data
            vm.nodeToBind.data_store_node.data.document_rules.data.push({
              is_active: _.get(assignedVariableValue, "is_active", 0),
              rule_column_name: value.col_name,
              rule_column_type: Vue.filter("capitalize")(value.type),
              rule_column_value: assignedVariableValue
                ? assignedVariableValue.rule_column_value
                : ""
            });
          }
        }
      });
    },

    handleOrderByClear() {
      this.showC1 = false;
      this.showC2 = false;
      this.additionalOrderBy = [
        {
          column: "",
          sortBy: ""
        },
        {
          column: "",
          sortBy: ""
        }
      ];
    },

    handleAdditionalOrderByClear() {
      this.showC2 = false;
      this.additionalOrderBy = [
        {
          column: "",
          sortBy: ""
        },
        {
          column: "",
          sortBy: ""
        }
      ];
    },

    handleOrderBySelection() {
      if (!_.isEmpty(this.orderBy.column)) {
        this.showC1 = true;
      }
    },

    handleAdditionalOrderBySelection() {
      if (!_.isEmpty(this.additionalOrderBy[0].column)) {
        this.additionalOrderBy[0].sortBy = !_.isEmpty(
          this.additionalOrderBy[0].sortBy
        )
          ? this.additionalOrderBy[0].sortBy
          : "desc";
        this.showC2 = true;
      }
      if (!_.isEmpty(this.additionalOrderBy[1].column)) {
        this.additionalOrderBy[1].sortBy = !_.isEmpty(
          this.additionalOrderBy[1].sortBy
        )
          ? this.additionalOrderBy[1].sortBy
          : "desc";
      }
    },

    handleVariableRuleChange(option) {
      return row => {
        this.$nextTick(() => {
          this.$set(row, "variable_name", option.label);
          this.$set(row, "variable_id", option.value);
          this.$set(row, "msg", option.msg);
        });
      };
    },

    /**
     * Reset the variable rules back to empty array
     */
    purgeJsonPathToStudioVariableMapping() {
      this.variableRules.splice(0);
    },

    saveExpression({ expression, complexVariables }) {
      return row => {
        this.displayExpressionBuilderModal = false;
        row.rule_column_value = expression;
        this.complexVariables = complexVariables;
      };
    },
    handleCancel() {
      this.displayExpressionBuilderModal = false;
    },

    handleVariableSet(variable) {
      if (!(this.updateActionSelected && !variable.is_active)) {
        this.variableToSet = variable;
        this.displayExpressionBuilderModal = true;
      }
    },

    arrayRule() {
      let arrayRule = _.find(
        this.variableRules,
        rule => rule.variable_name === this.arrayVariableName
      );
      let { variable_name, variable_id } = this.arrayVariable;
      if (!arrayRule) {
        arrayRule = {
          rule_value: "root",
          default_value: "",
          variable_name,
          variable_id,
          array_variable: true,
          variable_type: "array"
        };
        this.nodeToBind.data_store_node.data.variable_rules.data.push(
          arrayRule
        );
      }
      return arrayRule;
    },
    previewResponse() {
      let requestData = [];
      requestData[
        "data_store_id"
      ] = this.nodeToBind.data_store_node.data.data_store_id;
      requestData["where"] = this.eb.root.toJSON();
      requestData["row_limit"] = this.rowLimit;
      requestData["order_by"] = this.orderBy;
      requestData["task_id"] = this.task_id;
      this.setRequestData(requestData);
      this.$nextTick(() => {
        EventBus.$emit("documents-changed", this.dsData, { page: 1 });
        if (!this.isNodeSubmit) {
          this.showModal = true;
        }
      });
    },

    getDataStoreCollectionStructure(data_store_id) {
      if (data_store_id > 0) {
        const dataStoresCloned = _.cloneDeep(this.dataStores);
        let selectedDataStore = _.filter(dataStoresCloned, {
          data_store_id: data_store_id
        });
        if (selectedDataStore[0].display_name.includes(SYSTEM_LOG_DS)) {
          return selectedDataStore[0].collection_structure.filter(
            item => item.col_name !== "pop_region_id"
          );
        } else {
          return selectedDataStore[0].collection_structure;
        }
      }
      return [];
    },

    getSampleValue(type) {
      let value = _.get(this.dataTypeSampleValues, type);
      return _.isUndefined(value) ? "" : value;
    },

    setJsonFormatOfCollectionStructure() {
      let collectionStructure = _.get(
        this.dataStore,
        "collection_structure",
        {}
      );
      if (!_.isEmpty(collectionStructure)) {
        _.remove(collectionStructure, { col_name: "details", type: "object" });
        _.remove(collectionStructure, value => {
          return (
            _.get(this.dataStore, "is_log", 0) &&
            _.includes(value.col_name, "details.")
          );
        });
        let key = Object.assign(
          ...collectionStructure.map(val =>
            Object.assign({}, this.defaultDataStoreColumnsToSampleValue, {
              [val.col_name]: !val.secure
                ? this.getSampleValue(val.type)
                : this.getSampleValue("secure")
            })
          )
        );
        let metaInformation = _.cloneDeep({ ...this.responseMetaData });
        if (this.affectedRowIDFoundInMetaForOps.includes(this.operationName)) {
          _.set(metaInformation, "ws_response_meta.additional_info._id", "");
        }

        if (this.onlyMeta.includes(this.operationName)) {
          this.collectionStructureJson = metaInformation;
        } else {
          this.collectionStructureJson = {
            ws_response_data: [key],
            ws_response_meta: metaInformation["ws_response_meta"]
          };
        }
      }
    },

    operationNameChange() {
      if (!this.rowLimitOrderByShow) {
        this.rowLimit = this.getDefaultRowLimit(true);
        this.orderBy = this.getDefaultOrderBy();
      }
      this.setJsonFormatOfCollectionStructure();

      this.showC1 =
        this.operationName === "fetch" && !_.isEmpty(this.orderBy.column);
      this.showC2 =
        this.operationName === "fetch" &&
        !_.isEmpty(this.additionalOrderBy[0].column);
    },
    validateQueryCondition: (object, elements) => {
      let queryConditionBckUp = _.cloneDeep(object);
      let self = this;
      if (_.isObject(queryConditionBckUp) && !_.isEmpty(queryConditionBckUp)) {
        _.forOwn(queryConditionBckUp, function(value, key) {
          if (
            _.isObject(queryConditionBckUp[key]) &&
            _.isEmpty(queryConditionBckUp[key])
          ) {
            self.$notify({
              message: __("Please remove empty Query conditions"),
              type: "error"
            });
          } else if (Object.keys(queryConditionBckUp[key]).length > 0) {
            this.validateQueryCondition(queryConditionBckUp[key], elements);
          }
        });
        // remove any leftover undefined values from the delete
        // operation on an array
        // if (_.isArray(queryConditionBckUp))
        //   _.pull(queryConditionBckUp, undefined);
      } else {
        this.$notify({
          message: __("Please remove empty Query conditions"),
          type: "error"
        });
      }

      return queryConditionBckUp;
    },

    setAdditionalOrderByColumns() {
      let additionalOrderBy = [];
      if (
        !_.isEmpty(this.additionalOrderBy[0].column) &&
        !_.isEmpty(this.additionalOrderBy[0].sortBy)
      ) {
        additionalOrderBy.push({
          column: this.additionalOrderBy[0].column,
          sortBy: this.additionalOrderBy[0].sortBy
        });
      }

      if (
        !_.isEmpty(this.additionalOrderBy[1].column) &&
        !_.isEmpty(this.additionalOrderBy[1].sortBy)
      ) {
        additionalOrderBy.push({
          column: this.additionalOrderBy[1].column,
          sortBy: this.additionalOrderBy[1].sortBy
        });
      }
      if (!_.isEmpty(additionalOrderBy)) {
        this.$set(
          this.nodeToBind.data_store_node.data.query_condition.data
            .order_by_column_name,
          "additionalOrderBy",
          additionalOrderBy
        );
      }
    },

    cleanUpQueryCondition() {
      if (this.queryConditionShow || this.assignIdFilterShow) {
        this.$set(this.nodeToBind.data_store_node.data, "query_condition", {});
        this.$set(
          this.nodeToBind.data_store_node.data.query_condition,
          "data",
          {}
        );
        this.$set(
          this.nodeToBind.data_store_node.data.query_condition.data,
          "query_condition",
          {}
        );
        this.$set(
          this.nodeToBind.data_store_node.data.query_condition.data,
          "order_by_column_name",
          {}
        );
        let rowLimitObj = {
          rowLimit: this.rowLimit,
          orderBy: this.orderBy
        };

        if (this.assignIdFilterShow) {
          this.queryConditions = this.updateOrDeleteQueryCondition;
        } else {
          this.queryConditions = this.eb.root.toJSON();
        }

        this.nodeToBind.data_store_node.data.query_condition.data.order_by_column_name = rowLimitObj;
      } else {
        this.$set(this.nodeToBind.data_store_node.data, "query_condition", {});
      }
    },

    cleanUpDocumentRules() {
      this.documentRulesBkup = _.cloneDeep(
        this.nodeToBind.data_store_node.data.document_rules.data
      );
      if (this.assignColumnValuesTableShow) {
        let filteredDocumentRules = filterRowsIfSomeKeyValueIsAbsent(
          this.nodeToBind.data_store_node.data.document_rules.data,
          "rule_column_value"
        );
        this.$set(
          this.nodeToBind.data_store_node.data.document_rules,
          "data",
          []
        );

        if (this.operationName === "insert") {
          filteredDocumentRules = _.map(filteredDocumentRules, documentRule => {
            return { ...documentRule, is_active: 1 };
          });
        }

        this.nodeToBind.data_store_node.data.document_rules.data = filteredDocumentRules;
      } else {
        this.nodeToBind.data_store_node.data.document_rules.data = [];
      }
    },

    cleanUpNodeToPrepareForSubmit() {
      // do necessary operations on a cloned version of nodeToBind obj
      // that return the nodeToBind object
      // in exactly the same way as an node object of this
      // type is returned from the backend

      // as I do not require any cleanup to do here in this particular case,
      // I am just sending back a cloned version of nodeToBind obj
      // this.setColumnTableValues();

      let nodeToBind = _.cloneDeep(this.nodeToBind);

      // clean up should happen on a clone deep version of nodeToBind, not on actual nodeToBind
      _.remove(
        nodeToBind.data_store_node.data.variable_rules.data,
        obj => obj.variable_name.toString().trim() === ""
      );

      return nodeToBind;
    },
    cleanUpNode() {
      this.cleanUpDocumentRules();
      this.cleanUpQueryCondition();
      this.setJsonFormatOfCollectionStructure();
      this.setAdditionalOrderByColumns();
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
      this.nodeToBind.data_store_node.data.document_rules.data = this.documentRulesBkup;
    },

    formatter(row) {
      return this.capitalize(row.rule_column_type);
    }
  },
  watch: {
    operationName: {
      immediate: true,
      handler(val) {
        if (this.showIDFilterFor.includes(val)) {
          if (this.updateOrDeleteIdFilterValue !== this.idSearchValue) {
            this.updateOrDeleteIdFilterValue = this.idSearchValue;
          }
        }
      }
    },
    updateOrDeleteIdFilterValue: {
      deep: true,
      handler(val) {
        if (val !== this.idSearchValue) {
          this.idSearchValue = this.updateOrDeleteIdFilterValue;
          this.documentId = this.updateOrDeleteIdFilterValue;
        }
      }
    },

    "nodeToBind.data_store_node.data.data_store_id": {
      handler() {
        // Reset "Assign Values to Variables"
        this.purgeJsonPathToStudioVariableMapping();
      }
    },

    collectionStructureJson: {
      deep: true,
      handler() {
        let rule = this.arrayRule();
        let sg = new SchemaGenerator(this.collectionStructureJson);

        // The schema generated by the original response
        // and schema generated by the sample json after parsing the original response should be identical
        rule.default_value = JSON.stringify(sg.sampleJSON);

        // quick check to see if the above mentioned comment is right by checking the sample json generated by
        // identical schema are same (the difference in schema would be some constraints such as minLength of property
        // or something else, which we are not very concerned about)
      }
    },
    dataStore: {
      immediate: true,
      handler(val) {
        if (_.get(val, "is_log") && this.operationName === "delete") {
          this.operationName = "fetch";
          this.operationNameChange();
        } else {
          this.setJsonFormatOfCollectionStructure();
        }
      }
    },
    variableRules: {
      deep: true,
      handler(val) {
        this.newVariableCreated = _.some(
          val,
          variable => variable.variable_id === -1
        );
      }
    }
  }
};
</script>

<style scoped lang="scss">
/*.text-input ::v-deep textarea {*/
/*  resize: none;*/
/*}*/
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$content-theme-disabled-color: var(--theme-hover-color) !default;
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";
::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.response {
  margin-top: 20px;

  ::v-deep .el-divider--horizontal {
    margin: 0 0 10px 0;
    background-color: #dadde2;
    height: 2px;
  }
}

.extract-words-column {
  display: flex;
  flex: 1;
}
</style>
